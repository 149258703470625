import Vue from 'vue';
const sensors = require( 'sa-sdk-javascript' );
sensors.init( {
  server_url: 'https://sc.qbrqmhm.com/sa?project=JZT01', // 生产环境
  // server_url: 'https://sc.qbrqmhm.com/sa?project=JZT01TEST', // 测试环境
  heatmap: {
    scroll_notice_map: 'not_collect',
    clickmap: 'default'
  },
  is_track_single_page: true,
  use_client_time: true,
  show_log: true // 打印埋点日志
  // debug_mode: true
} );
// 神策挂载
Vue.prototype.$sensors = sensors;