<template>
  <div id="app" :style="{ overflowY: isLiveStyle ? 'hidden' : 'auto' }">
    <section class="main" ref="main">
      <router-view :key="$route.fullPath" />
    </section>
  </div>
</template>


<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "app",
  data() {
    return {
      isLiveStyle: true,
    };
  },
  created() {
    this.getUserInfoFn(); //获取用户信息
    this.getLevelInfo(); //获取等级信息
    this.getGiftList(); //获取礼物配置信息
    this.postDamuConfig(); //获取飞屏配置信息
    this.postTryConfig(); //获取试看配置信息
    this.getScrollAdver({ keys: "live_scroll_msg_config" }); //获取直播间滚动消息
    this.getTopAdver({ keys: "live_top_msg_config" }); //获取直播间顶置消息
    this.getPreviewConfig({ keys: "live_closed_img" }); //获取直播间预览信息
    if (localStorage.getItem("mainSwitch")) {
      //获取保存的特效按钮状态
      this.$store.commit(
        "SET_MAIN_SWITCH",
        JSON.parse(localStorage.getItem("mainSwitch"))
      );
    }
    // this.$store.commit('SET_TIME_ENTER', new Date())
  },
  destroyed() {
  },
  beforeDestroy() {
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.info.userInfo,
    }),
  },
  watch: {
  },
  // watch: {
  //   $route: {
  //     immediate: true,
  //     handler(to, from) {
  //       if (to.path.includes("/settings")) {
  //         this.isLiveStyle = false;
  //       } else {
  //         this.isLiveStyle = true;
  //       }
  //     },
  //   },
  // },
  methods: {
    ...mapActions({
      getUserInfoFn: "getUserInfoFn",
      getLevelInfo: "getLevelInfo",
      getGiftList: "getGiftList",
      getScrollAdver: "getScrollAdver",
      getTopAdver: "getTopAdver",
      getCustomeServe: "getCustomeServe",
      postDamuConfig: "postDamuConfig",
      getPreviewConfig: "getPreviewConfig",
      postTryConfig: "postTryConfig",
    }),
  },
};
</script>
<style lang="scss">
html,
body {
  margin: 0;
  padding: 0;
}
html,
body {
  font-family: "NotoSans", "PingFang SC", "Helvetica Neue", "Microsoft YaHei",
    Arial, Helvetica, sans-serif !important;

  -moz-user-select: none;
  -o-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.blueStyle {
  background: linear-gradient(180deg, #40479b 0%, #222565 100%);
  box-shadow: 0px -1px 4px 0px rgba(28, 28, 28, 0.5);
  background-color: transparent !important;
  .van-dialog__header {
    color: #fff;
  }
  .van-dialog__message {
    color: #fff;
  }
  .van-button--default {
    background-color: #2d3279;
  }
  .van-dialog__cancel {
    color: #fff;
  }
  .van-dialog__confirm {
    color: #919699;
  }
}
</style>

