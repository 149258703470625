import APIS from "../../http/apis";
import { Toast } from "vant";


const state = () => ({
    userInfo: JSON.parse(localStorage.getItem('userData')) || null,
    levelInfo: [],
    showPay: false,
    paySrc: "",
    giftList: [], //礼物列表
    AdverScrollData: "", //直播间跑马灯
    AdverTopData: "",  //直播间列表顶部跑马灯
    serveLink: "",//客服链接
    damuConfigList: {}, //飞屏配置信息
    tryConfigList: {}, // 试看配置信息
    enterTime: null
});

const mutations = {
    SET_USER_INFO(state, value) {
        state.userInfo = value;
    },
    SET_LEVEL_INFO(state, value) {
        state.levelInfo = value;
    },
    SET_IS_SHOW_PAY(state, bool) {
        state.showPay = bool;
    },
    SET_PAY_SRC(state, value) {
        state.paySrc = value;
    },
    SET_GIFT_LIST(state, value) {
        state.giftList = value;
    },
    SET_ADVER_SCROLL(state, value) {
        state.AdverScrollData = value;
    },
    SET_ADVER_TOP(state, value) {
        state.AdverTopData = value;
    },
    SET_CUSTOME_SERVE(state, value) {
        state.serveLink = value;
    },
    SET_DAMU_CONFIG(state, value) {
        state.damuConfigList = value;
    },
    SET_TRY_CONFIG(state, value) {
        state.tryConfigList = value;
    },
    SET_TIME_ENTER(state, value) {
        state.enterTime = value;
    },
};

const actions = {
    getUserInfoFn({ commit }, params) {
        return APIS.postUserInfo(params)
            .then(res => {
                if (res) {
                    const { code, data } = res;
                    if (code == 200) {
                        commit('SET_USER_INFO', data.info);
                        localStorage.setItem('userData', JSON.stringify(data.info));
                    } else {
                        Toast.fail(res.msg)
                    }
                    return res;
                }
            });
    },
    getLevelInfo({ commit }, params) {
        return APIS.postLevelInfo({ type: 1 })
            .then(res => {
                if (res) {
                    const { code, data } = res;
                    if (code == 200) {
                        commit('SET_LEVEL_INFO', data.list.user);
                    }
                    return res;
                }
            });
    },
    getGiftList({ commit }, params) {
        return APIS.getGiftList()
            .then(res => {
                if (res) {
                    const { code, data } = res;
                    if (code == 200) {
                        commit('SET_GIFT_LIST', data.list);
                    }
                    return res;
                }
            });
    },
    postDamuConfig({ commit }, params) {
        return APIS.postDamuConfig()
            .then(res => {
                if (res) {
                    const { code, data } = res;
                    if (code == 200 && data.list) {
                        commit('SET_DAMU_CONFIG', data.list);
                    }
                    return res;
                }
            });
    },
    postTryConfig({ commit }) {
        return APIS.postTryConfig()
            .then(res => {
                if (res) {
                    const { code, data } = res;
                    if (code == 200 && data) {
                        commit('SET_TRY_CONFIG', data);
                    }
                    return res;
                }
            });
    },
    getScrollAdver({ commit }, params) {
        return APIS.getConfigAdver(params)
            .then(res => {
                if (res) {
                    const { code, data } = res;
                    if (code == 200 && data?.data?.live_scroll_msg_config?.value) {
                        let arr = data.data.live_scroll_msg_config.value
                        let str = ""
                        arr.forEach(element => {
                            str += element
                        });
                        commit('SET_ADVER_SCROLL', str);
                    }
                    return res;
                }
            });
    },
    getTopAdver({ commit }, params) {
        return APIS.getConfigAdver(params)
            .then(res => {
                if (res) {
                    const { code, data } = res;
                    if (code == 200 && data?.data?.live_top_msg_config?.value) {
                        let arr = data.data.live_top_msg_config.value
                        let str = ""
                        arr.forEach(element => {
                            str += element
                        });
                        commit('SET_ADVER_TOP', str);
                    }
                    return res;
                }
            });
    },
    getCustomeServe({ commit }, params) {
        return APIS.getConfigAdver(params)
            .then(res => {
                if (res) {
                    const { code, data } = res;
                    if (code == 200 && data?.data?.link_conf?.value?.customer_service_url) {
                        let str = data.data.link_conf.value.customer_service_url
                        commit('SET_CUSTOME_SERVE', str);
                    }
                    return res;
                }
            });
    },
};

// getters
const getters = {
    // M_FooterList(state) {
    //     return state.footerList
    // },
}

export default {
    state,
    mutations,
    actions,
    getters
}
