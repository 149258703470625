import { get, post } from "./axiosConfig";
const APIS = {
  // 共用post请求
  postRequest: (payload = {}) => {
    return post(payload);
  },
  postGets: (payload = {}) => { //获取配置信息
    return get('/conf/info', payload);
  },
  getNavBar: (payload = {}) => { //获取配置信息
    return get('/comm/conf/nav', payload);
  },
  postLiveList: (payload = {}) => { //获取直播间列表
    return get('/live/list', payload);
  },
  postLiveInfo: (payload = {}) => { //获取直播间信息
    return get('/live/info', payload);
  },
  postJoinLive: (payload = {}) => { //加入直播间信息
    return post('/live/join', payload);
  },
  postAnchormanRealList: (payload = {}) => { //通过主播ID获取直播间信息
    return post('/live/getLiveInfoByUid', payload);
  },
  postUserInfo: (payload = {}) => { //获取用户信息
    return get('/user/info', payload);
  },
  postImageFile: (payload = {}) => { //上传图片
    return post('/comm/flie', payload);
  },
  postUpdateUserInfo: (payload = {}) => { //修改用户信息
    return post('/user/update', payload);
  },
  postLevelInfo: (payload = {}) => { //获取等级配置信息
    return get('/comm/conf/level', payload);
  },
  postDiamondList: (payload = {}) => { //获取钻石购买配置
    return get('/comm/conf/pay/diamond', payload);
  },
  postRechargeRecord: (payload = {}) => { //获取用户充值记录
    return get('/user/top/up/record', payload);
  },
  postConsumeRecord: (payload = {}) => { //获取消费记录
    return get('/user/consume/record', payload);
  },
  postPayment: (payload = {}) => { //创建支付
    return post('/pay/create', payload);
  },
  getGiftList: (payload = {}) => { //获取礼物配置
    return get('/comm/conf/gift', payload);
  },
  getConfigAdver: (payload = {}) => { //获取配置信息
    return get('/conf/info', payload);
  },
  getLiveRank: (payload = {}) => { //获取直播间排行榜
    return get('/live/rank', payload);
  },
  getPayGift: (payload = {}) => { //赠送礼物
    return post('/pay/buy', payload);
  },
  postPayDamu: (payload = {}) => { //发送弹幕
    return post('/pay/buy', payload);
  },
  postLeaveLive: (payload = {}) => { //离开直播间
    return post('/live/leave', payload)
  },
  GetMessageNum: (payload = {}) => { //获取未读消息数
    return get('/user/sys/message/num', payload)
  },
  postSendMessage: (payload = {}) => { //向聊天室发送消息
    return post('/live/send/msg', payload)
  },
  postIsBan: (payload = {}) => { //聊天室禁言判断
    return post('/live/user/is/ban', payload)
  },
  postSysMessage: (payload = {}) => { //获取系统消息
    return get('/user/sys/message', payload)
  },
  postHeartBeat: (payload = {}) => { //直播间心跳
    return post('/live/room/heartbeat', payload)
  },
  postDamuConfig: (payload = {}) => { //获取飞屏配置信息
    return get('/comm/conf/fs', payload)
  },
  postTryConfig: (payload = {}) => { //试看配置
    return post('/live/try_config', payload)
  }
  // 以下是旧接口
  // 获取用户信息
  // getMemberInfo: (payload = {}) => {
  //   return post(payload);
  // },
  // giftUse: (payload = {}) => {
  //   return post({ ...payload });
  // },
  // // 新的礼物接口
  // getGiftListNew: (payload = {}) => {
  //   return post({ ...payload });
  // },

  // // 余额更新
  // getMoneyRefresh: (payload = {}) => {
  //   return post({ ...payload });
  // },

  // // 排行榜
  // getGiftTop3: (payload = {}) => {
  //   return post({ ...payload })
  // },
  // // vip座驾特效
  // getCarEffectList: (payload = {}) => {
  //   return post({ ...payload })
  // },
}

export default APIS;
